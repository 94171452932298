'use client';
import { useState } from 'react';
import {
    Button, Input, Alert
} from "@heroui/react";
import { useRouter } from 'next/navigation';
import { Search } from 'lucide-react';

const SearchBar = ({ placeHolder }: { placeHolder?: string }) => {
    const router = useRouter();
    const [q, setQ] = useState('');
    const [placeholder, setPlaceholder] = useState(placeHolder ? placeHolder : 'Search for companies');
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const handleSearchInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setQ(e.target.value);
    };

    const handleSearch = async () => {
        if (q.length > 2) {
            setIsLoading(true);
            router.push(`/companies?q=${q}`);
        } else {
            setIsOpen(true);
        }
    };

    return (
        <div className="flex justify-center flex-col">
            {isOpen && q.length < 3 && (
                <Alert className="relative w-full px-1 py-2 bg-white border border-gray-300 rounded-md shadow-lg"
                    description="Search query must be longer than 2 characters" />
            )}
            <Input
                type="text"
                placeholder={placeholder}
                size="lg"
                fullWidth
                onChange={handleSearchInput}
                onKeyDown={async (e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        handleSearch();
                    }
                }}
                endContent={
                    <Button onPress={handleSearch} size="sm" variant="solid" color="primary" isLoading={isLoading}>
                        <Search className='w-5 h-5 md:w-8 md:h-8' />
                        <span className="hidden sm:inline">Search</span>
                    </Button>
                }
            />
        </div>
    );
};

export default SearchBar;
