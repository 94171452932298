'use client';
import { useState } from 'react';
import { Button, Input, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter } from "@heroui/react";
import { BackgroundBeams } from '@/components/ui/background-beams';
import { subscribeNewsletter } from 'actions/subscribe';

export default function NewsletterSection() {
    const [isSubscribed, setIsSubscribed] = useState(false);

    const handleSubscribe = async (event: React.FormEvent) => {
        event.preventDefault();
        console.log('Subscribing to newsletter');
        const form = event.target as HTMLFormElement;
        await subscribeNewsletter((form.elements.namedItem('email') as HTMLInputElement).value);
        setIsSubscribed(true);
    };

    return (
        <section id="newsletter" className="bg-gradient-to-br from-blue-100 to-primary-100">
            <div className="relative flex h-[20rem] w-full flex-col items-center justify-center rounded-md antialiased">
                <div className="mx-auto max-w-2xl p-4 z-10">
                    <h2 className="relative z-10 bg-gradient-to-b from-primary-400 to-primary-600 bg-clip-text text-center font-sans text-xl font-bold text-transparent md:text-3xl">
                        Subscribe to our Newsletter
                    </h2>
                    <p className="relative z-10 mx-auto my-2 max-w-lg text-center text-sm text-primary-500">
                        Get the latest scam alerts, news and updates delivered to your inbox.
                    </p>
                    {isSubscribed ? (
                        <>
                            <div className="item flex max-w-[500px] flex-row justify-center gap-2 md:flex-col">
                                <Modal
                                    isOpen={isSubscribed}
                                    placement="center"
                                    motionProps={{
                                        variants: {
                                            enter: {
                                                y: 0,
                                                opacity: 1,
                                                transition: {
                                                    duration: 0.3,
                                                    ease: 'easeOut',
                                                },
                                            },
                                            exit: {
                                                y: -20,
                                                opacity: 0,
                                                transition: {
                                                    duration: 0.2,
                                                    ease: 'easeIn',
                                                },
                                            },
                                        },
                                    }}
                                >
                                    <ModalContent className="text-center">
                                        <>
                                            <ModalHeader className="flex flex-col gap-1">
                                                <span className="text-2xl font-bold">Subscription Successful</span>
                                            </ModalHeader>
                                            <ModalBody>
                                                <p>Thank you for subscribing. You will receive our updates in your inbox.</p>
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button color="default" variant="light" onPress={() => setIsSubscribed(false)}>
                                                    Close
                                                </Button>
                                            </ModalFooter>
                                        </>
                                    </ModalContent>
                                </Modal>
                            </div>
                            <p className="flex h-screen items-center justify-center text-lg font-bold">
                                Thank you for subscribing. You will receive our updates in your inbox.
                            </p>
                        </>
                    ) : (
                        <form onSubmit={handleSubscribe} className="z-10 flex flex-col items-center gap-4">
                            <Input
                                type="email"
                                name="email"
                                placeholder="Enter your email"
                                required
                                className="bg-primary-950 relative z-10 w-full rounded-lg border border-primary-800 placeholder:text-primary-700 focus:ring-2 focus:ring-teal-500"
                            />
                            <Button type="submit" color="primary" size="md" className="font-semibold text-white hover:scale-105">
                                Subscribe Now
                            </Button>
                        </form>
                    )}
                </div>
                <BackgroundBeams />
            </div>
        </section>
    );
}